import {Component, DoCheck, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {CsrRecurringDetails, McForm, McValueLabelList} from '@miticon-ui/mc-core';
export enum PaymentType {
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY',
  HALF_YEARLY = 'HALF_YEARLY',
  YEARLY = 'YEARLY'
}
@Component({
  selector: 'mc-consumer-consumer-360-create-contract-payments-part',
  templateUrl: './mc-consumer-consumer-360-create-contract-payments-part.component.html',
  styleUrls: ['./mc-consumer-consumer-360-create-contract-payments-part.component.scss']
})

export class McConsumerConsumer360CreateContractPaymentsPartComponent implements OnInit, DoCheck {

  paymentsVll!: McValueLabelList;
  selectedPaymentCd!: string;
  @Input() mcForm!: McForm;
  recurringDetails = new CsrRecurringDetails();
  @Output() emitValidPaymentTab = new EventEmitter();


  ngOnInit() {
    this.paymentsVll = CsrRecurringDetails.getTypeCdVll();
  }

  onPaymentChangeEmit(paymentCd: string) {
    this.selectedPaymentCd = paymentCd;
  }

  ngDoCheck(): void {
    const paymentValue = this.mcForm.getControl('ctrlPayment')?.value.value;
    this.emitValidPaymentTab.emit(paymentValue);
  }
}
