<mcc-message class="w-100" [errorMsg]="message"></mcc-message>

<div class="card">
  <!--    Details part-->
  <h2 class="title-medium-dark mt-small">{{ 'cc.contract-360' | translate }}</h2>
  <div class="card dialog-header-card">
    <div class='dialog-row-wrapper dialog-header'>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.contract-360' | translate}}:</span>
        <span class='dialog-col-value'>{{ selectedContract.name }}</span>
      </div>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.contract-360.type' | translate}}:</span>
        <span class='dialog-col-value'>{{ getTypeCdLabel(selectedContract.typeCd) }}</span>
      </div>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.contract-360.duration' | translate}}:</span>
        <span class='dialog-col-value'>{{ selectedContract.durationInMonths + " " + ((selectedContract.durationInMonths === 1 ? "cc.common.month" : "cc.my-factoring.months") | translate | lowercase) }}</span>
      </div>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.common.original-amount' | translate}}:</span>
        <span class='dialog-col-value'>{{ selectedContract.originalAmount + '€' }}</span>
      </div>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.common.discount' | translate}}:</span>
        <span class='dialog-col-value'>{{ selectedContract.discount ? selectedContract.discount.value + '%' : '-' }}</span>
      </div>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.billing.total-amount' | translate}}:</span>
        <span class='dialog-col-value'>{{ selectedContract.totalAmount + '€' }}</span>
      </div>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ getPriceLabel(selectedContract.typeCd) | translate}}:</span>
        <span class='dialog-col-value'>{{ selectedContract.price + '€' }}</span>
      </div>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'mem.product.one-time' | translate}}:</span>
        <span class='dialog-col-value'>{{ selectedContract.priceOneTime ? selectedContract.priceOneTime + '€' : '-' }}</span>
      </div>
    </div>
  </div>
  <div class='dialog-row-wrapper'>
    <div class='dialog-col'>
      <span class='dialog-col-label'>{{ ('cc.contract-360' | translate) + ' ' + ('cc.factoring.start-date' | translate | lowercase) }}:</span>
      <span class='dialog-col-value'>
        {{ csrContract.startDate ? (csrContract.startDate | date : "dd.MM.YYYY") : '-' }}
      </span>
    </div>
    <div class='dialog-col'>
      <span class='dialog-col-label'>{{ ('cc.contract-360' | translate) + ' ' + ('cc.factoring.end-date' | translate | lowercase) }}:</span>
      <span class='dialog-col-value'>
        {{ csrContract.endDate ? (csrContract.endDate | date : "dd.MM.YYYY") : '-' }}
      </span>
    </div>
  </div>
  <div class="divider"></div>

  <!-- Payment and Payment Method part-->
  <h2 class="title-medium-dark">{{ 'cc.my-factoring.payment' | translate }}</h2>
  <div class='dialog-row-wrapper'>
    <div class='dialog-col'>
      <span class='dialog-col-label'>{{ 'cc.my-factoring.payment' | translate}}:</span>
      <span class='dialog-col-value'>
        {{
        csrContract.recurringDetails.getTypeCdLabel() ?
          csrContract.recurringDetails.getTypeCdLabel() : '-'
        }}
      </span>
    </div>
  </div>
  <h2 class="title-medium-dark mt-medium">{{ 'cc.my-factoring.payment-method' | translate }}</h2>
  <div class='dialog-row-wrapper'>
    <div class='dialog-col'>
      <span class='dialog-col-label'>{{ 'cc.my-factoring.payment-method' | translate}}:</span>
      <span class='dialog-col-value'>
        {{ csrContract.paymentDetails.getTypeCdLabel() ? csrContract.paymentDetails.getTypeCdLabel() : '-' }}
      </span>
    </div>
    <ng-container *ngIf="this.csrContract.paymentDetails?.isTypeCdDIRECT_DEBIT() && primaryBankAccount">
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.common.account-owner' | translate}}:</span>
        <span class='dialog-col-value'>
          {{ primaryBankAccount.owner ? primaryBankAccount.owner : '-' }}
        </span>
      </div>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.common.bank-name' | translate}}:</span>
        <span class='dialog-col-value'>
          {{ primaryBankAccount.bankName ? primaryBankAccount.bankName : '-' }}
        </span>
      </div>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.common.iban' | translate}}:</span>
        <span class='dialog-col-value'>
          {{ primaryBankAccount.iban ? primaryBankAccount.iban : '-' }}
        </span>
      </div>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.consumers.edit.bic' | translate}}:</span>
        <span class='dialog-col-value'>
          {{ primaryBankAccount.bic ? primaryBankAccount.bic : '-' }}
        </span>
      </div>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.consumer.overview.sepa-mandate-id' | translate}}:</span>
        <span class='dialog-col-value'>
          {{ primaryBankAccount.sepaMandateId ? primaryBankAccount.sepaMandateId : '-' }}
        </span>
      </div>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.consumer.overview.sepa-mandate-date-of-signature' | translate}}:</span>
        <span class='dialog-col-value'>
          {{ primaryBankAccount.sepaMandateDate ? (primaryBankAccount.sepaMandateDate | date : "dd.MM.YYYY") : '-' }}
        </span>
      </div>
    </ng-container>
    <ng-container *ngIf="this.csrContract.paymentDetails?.isTypeCdTRANSFER() && mcBankAccount">
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.common.account-owner' | translate}}:</span>
        <span class='dialog-col-value'>
          {{ mcBankAccount.accountOwner ? mcBankAccount.accountOwner : '-' }}
        </span>
      </div>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.common.bank-name' | translate}}:</span>
        <span class='dialog-col-value'>
          {{ mcBankAccount.bankName ? mcBankAccount.bankName : '-' }}
        </span>
      </div>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.common.iban' | translate}}:</span>
        <span class='dialog-col-value'>
          {{ mcBankAccount.iban ? mcBankAccount.iban : '-' }}
        </span>
      </div>
      <div class='dialog-col'>
        <span class='dialog-col-label'>{{ 'cc.consumers.edit.bic' | translate}}:</span>
        <span class='dialog-col-value'>
          {{ mcBankAccount.bic ? mcBankAccount.bic : '-' }}
        </span>
      </div>
    </ng-container>
  </div>
</div>
