import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ActivatedRoute, Router } from '@angular/router';
import { MkFilterConfig, MkFilterItemType, MkFilterOutput, MkFilterValue, MkTableConfig } from '@miticon-ui/mc-components';
import {EbFactoringService, EbPaymentReportService, McBoolean, McEntity2List, McGod, PmTransaction, PmTransactionFilter, PmTransactionList, SortCriteriaList, WebFile} from '@miticon-ui/mc-core';
import { ToastrService } from 'ngx-toastr';
import { AppSharedMatBottomSheetComponent } from '../../../app-shared/components/app-shared-mat-bottom-sheet/app-shared-mat-bottom-sheet.component';

@Component({
  selector: 'pm-transactions-list',
  templateUrl: './pm-transactions-list.component.html',
  styleUrls: ['./pm-transactions-list.component.scss'],
})
export class PmTransactionsListComponent implements OnInit, OnDestroy {
  pmTransactionFilter = new PmTransactionFilter();
  entitiesWithTransactions = new McEntity2List();
  transactionsExportedFlg = false;
  showExportMcb = new McBoolean();
  showRecordPaymentMcb  = new McBoolean();
  actionPmTransaction!: any;
  parentFlg!: boolean;
  successExportMsg!: string;
  pmTransactionList = new PmTransactionList();
  tableConfig = new MkTableConfig();
  filterConfig = new MkFilterConfig();
  isLoading = false;
  showConsumerProfileMcb = new McBoolean();
  consumerId: number;
  checkedTransactionList = new PmTransactionList();
  showPauseTransactionMcb = new McBoolean();
  isExportAll = false;
  showStornoMcb = new McBoolean();
  searchTooltip = McGod.t('cc.common.search-by-consumer-name-description-contract-number-internal-external-id');

  // ---------------------------------------------------------------------
  constructor(private router: Router,private ebReportService: EbPaymentReportService, private route: ActivatedRoute, private toastr: ToastrService, private factoringService: EbFactoringService, private matBottomSheet: MatBottomSheet) {}

  // ---------------------------------------------------------------------
  ngOnInit() {
    if (this.route.snapshot.routeConfig?.path === 'out') {
      this.parentFlg = true;
    } else {
      this.parentFlg = false;
    }

    this.pmTransactionList = new PmTransactionList();
    this.pmTransactionList.setPager(0, 100);

    this.entitiesWithTransactions.loadAllWhereFactoringTransactionParentIsLoggedInEntity(() => {
      this.entitiesWithTransactions.items.sort((a, b) => a.name.localeCompare(b.name));
      this.initFilterConfig();
    });

    this.pmTransactionFilter.outTransactionFlg = this.parentFlg;
    this.actLoad(0, 100, this.pmTransactionFilter);
    this.initTableConfig();
  }

  actLoad(pageNumber: number, pageSize: number, filters: PmTransactionFilter) {
    this.isLoading = true;
    this.pmTransactionList.pageNum = pageNumber;
    this.pmTransactionList.pageItemsPerPageCount = pageSize;
    filters.outTransactionFlg = this.parentFlg;
    this.pmTransactionList.loadByFilter(filters, () => {
      this.checkedTransactionList = new PmTransactionList();
      this.isLoading = false;
      this.pmTransactionList.items.map((item) => (item.navigation = `/entity/${McGod.getLoggedEntityIdFromToken()}/transactions/${item.id}`));
    });
  }

  initTableConfig() {
    this.tableConfig.addColumnId([`/entity/${McGod.getLoggedEntityIdFromToken()}/transactions`], 'id');
    if (this.parentFlg) {
      this.tableConfig.addColumnStandard(McGod.t('cc.common.create-type'), 'sourceTypeCd', 250, 'sourceTypeCd');
      this.tableConfig.addColumnStandard(McGod.t('cc.consumer.studio'), 'mcEntity.name');
    }
    this.tableConfig.addColumnButtonAction(McGod.t('cc.my-factoring.consumer'), 'csrConsumer.displayName', 'fas fa-user tr-icon', PmTransaction.ACTION_VIEW_CONSUMER_PROFILE);
    this.tableConfig.addColumnStandard(McGod.t('cc.common.view.description'), 'description', 250, 'description');
    if (this.parentFlg) {
      this.tableConfig.addColumnStandard(McGod.t('cc.common.view.matching-id'), 'matchingId', 250);
    }
    this.tableConfig.addColumnStandard(McGod.t('cc.consumers.imports.created-at'), 'getCreatedDatetimeStr()', 250, 'sysCreatedDatetime');
    this.tableConfig.addColumnStandard(McGod.t('cc.transactions.payment-due-date'), 'getDueDateStr()', 250, 'dueDate');
    this.tableConfig.addColumnStandard(McGod.t('cc.common.view.amount-due'), 'getAmountDue()', 250);
    this.tableConfig.addColumnInnerHtml(McGod.t('cc.common.status'), 'getIconLabel()', 'statusCd');
  }

  initFilterConfig() {
    const entityFilterValues = this.entitiesWithTransactions.items.map((entity) => new MkFilterValue(entity.name, entity.id));
    const filteredEntities = [...new Map(entityFilterValues.map((item) => [item.value, item])).values()];
    const createTypeFilter = PmTransaction.getSourceTypeCdVll().items.map((item) => new MkFilterValue(item.label, item.value));
    const statusFilter = PmTransaction.getStatusCdVll().items.map((item) => new MkFilterValue(item.label, item.value));
    const dunningFilter = PmTransaction.getDunningCdVll().items.map((item) => new MkFilterValue(item.label, item.value));
    const pauseFilter = PmTransaction.getPauseUnpausedCdVll().items.map((item) => new MkFilterValue(item.label, item.value));
    const typeFilter = PmTransaction.getFactoredServiceTypeCdVll().items.map((item) => new MkFilterValue(item.label, item.value));

    this.filterConfig.addItem(MkFilterItemType.MULTISELECT, McGod.t(PmTransaction.FILTER_CREATE_TYPE), createTypeFilter);
    this.filterConfig.addItem(MkFilterItemType.MULTISELECT, McGod.t(PmTransaction.FILTER_STATUS), statusFilter);
    this.filterConfig.addItem(MkFilterItemType.SELECT, McGod.t(PmTransaction.FILTER_PAUSE), pauseFilter, 'pausedFilterOptionCd');
    this.filterConfig.addItem(MkFilterItemType.MULTISELECT, McGod.t(PmTransaction.FILTER_ENTITIES), filteredEntities);
    this.filterConfig.addItem(MkFilterItemType.SELECT, McGod.t(PmTransaction.FILTER_DUNNING), dunningFilter);
    this.filterConfig.addItem(MkFilterItemType.MULTISELECT, McGod.t(PmTransaction.FILTER_TYPE), typeFilter);
    this.filterConfig.addItem(MkFilterItemType.RANGE_DATEPICKER, McGod.t(PmTransaction.FILTER_DATE_RANGE));
  }

  onFilterChanged(filters: MkFilterOutput) {
    const transactionFilter = new PmTransactionFilter();
    this.pmTransactionList.setSortB(filters.sort.sortProperty, filters.sort.sortType);
    transactionFilter.outTransactionFlg = this.parentFlg;
    transactionFilter.searchTerm = filters.search;
    transactionFilter.paymentMethod = MkFilterOutput.convertFilterSelectionToArray(filters.selections[McGod.t(PmTransaction.FILTER_CREATE_TYPE)]);
    transactionFilter.statusCds = MkFilterOutput.convertFilterSelectionToArray(filters.selections[McGod.t(PmTransaction.FILTER_STATUS)]);
    transactionFilter.pausedFilterOptionCd = filters.selections[McGod.t(PmTransaction.FILTER_PAUSE)];
    transactionFilter.childEntityIds = MkFilterOutput.convertFilterSelectionToArray(filters.selections[McGod.t(PmTransaction.FILTER_ENTITIES)]);
    transactionFilter.dunningCompletedFilterOptionCd = filters.selections[McGod.t(PmTransaction.FILTER_DUNNING)];
    transactionFilter.factoringTypeWithDelayFilterOptionCds = MkFilterOutput.convertFilterSelectionToArray(filters.selections[McGod.t(PmTransaction.FILTER_TYPE)]);
    transactionFilter.transactionPaymentDueDateTimeFromDate = filters.selections[McGod.t(PmTransaction.FILTER_DATE_RANGE)]?.startDate;
    transactionFilter.transactionPaymentDueDateTimeToDate = filters.selections[McGod.t(PmTransaction.FILTER_DATE_RANGE)]?.endDate;
    this.actLoad(filters.pageEvent.pageIndex, filters.pageEvent.pageSize, transactionFilter);
  }

  onItemsSelected(items: PmTransaction[]) {
    if (items.length === 0) {
      this.matBottomSheet.dismiss();
    }
    this.checkedTransactionList = new PmTransactionList();
    this.checkedTransactionList.items = items;
    const parentData = [
      {icon: 'import_export', title: McGod.t('cc.common.export'), action: PmTransaction.ACTION_EXPORT},
      {icon: 'pause', title: 'Pause/Unpause Transactions', action: PmTransaction.ACTION_PAUSE_TRANSACTIONS},
      {icon: 'save', title: McGod.t('cc.transactions.record-payment'), action: PmTransaction.ACTION_RECORD_PAYMENT},
      {icon: 'cancel', title: McGod.t('cc.common.storno'), action: PmTransaction.ACTION_STORNO},
    ];
    const childData = [
      {icon: 'cancel', title: McGod.t('cc.common.storno'), action: PmTransaction.ACTION_STORNO},
    ];
    const bulkContainerLength = document.getElementsByClassName('bulk-container').length;
    if (items.length > 0 && !bulkContainerLength && !this.isExportAll) {
      const bulkDialog = this.matBottomSheet.open(AppSharedMatBottomSheetComponent, {
        closeOnNavigation: true,
        hasBackdrop: false,
        data: this.parentFlg ? parentData : childData
      });

      bulkDialog.afterDismissed().subscribe((bulkAction) => {
        switch (bulkAction) {
          case PmTransaction.ACTION_PAUSE_TRANSACTIONS:
            const isItemRejected = this.checkedTransactionList.items.some((item) => item.isStatusCdREJECTED());
            const isItemPaid = this.checkedTransactionList.items.some((item) => item.isStatusCdPAID());
            const isItemSentToInkasso = this.checkedTransactionList.items.some((item) => item.isStatusCdSENT_TO_INKASSO());
            const isItemSettled = this.checkedTransactionList.items.some((item) => item.isStatusCdSETTLED());
            const isItemCancelled = this.checkedTransactionList.items.some((item) => item.isStatusCdCANCELLED());
            const selectedTransactiosPausedFlg = this.checkedTransactionList.items.map((item) => item.flgPaused);

            const areEveryPauseFlgValuesEqual = selectedTransactiosPausedFlg.every((val, ind, arr) => val === arr[0]);

            if (this.checkedTransactionList.getCount() > 1 && !areEveryPauseFlgValuesEqual) {
              this.onItemsSelected(items);
              this.toastr.error(`${McGod.t('cc.transactions.selected-transactions-does-not-have-same-pause-status')}.`, '', { timeOut: 4000, extendedTimeOut: 4000 });
            }
            if (!isItemRejected && !isItemSentToInkasso && !isItemSettled && !isItemCancelled && areEveryPauseFlgValuesEqual) {
              this.showPauseTransactionMcb.setTrue();
            }
            if (isItemRejected || isItemSentToInkasso || isItemSettled || isItemCancelled) {
              this.onItemsSelected(items);
              this.toastr.error(`${McGod.t('cc.transactions.transactions-that-can-be-paused')}.`, '', { timeOut: 4000, extendedTimeOut: 4000 });
            }
            break;

          case PmTransaction.ACTION_EXPORT:
            this.isExportAll = false;
            this.actShowExportMcb();
            break;

          case PmTransaction.ACTION_RECORD_PAYMENT:
            this.isExportAll = false;
            this.actShowRecordPaymentMcb();
            break;
          case PmTransaction.ACTION_STORNO:
            const isInvalid = this.isStornoPossible(this.checkedTransactionList);
            if (isInvalid) {
              this.onItemsSelected(items);
              this.toastr.error(`Please select transactions which don't have following statuses: Canceled, Exported, Rejected and Settled.`, '', { timeOut: 4000, extendedTimeOut: 4000 });
            } else {
              this.showStornoMcb.setTrue();
            }
        }
      });
    }
  }

  actExportTransactionsSaved(exportType: string) {
    const sortCriteriaList = new SortCriteriaList();
    this.checkedTransactionList = new PmTransactionList();
    this.showExportMcb.setFalse();
    this.actLoad(0, 100, this.pmTransactionFilter);
  }

  actCanceledExportMcb() {
    if (this.transactionsExportedFlg) {
      this.checkedTransactionList = new PmTransactionList();
      this.showExportMcb.setFalse();
    } else {
      this.showExportMcb.setFalse();
    }
    if (!this.isExportAll) {
      this.onItemsSelected(this.checkedTransactionList.items);
    } else {
      this.isExportAll = false;
    }
  }

  actTransactionsExported() {
    this.transactionsExportedFlg = true;

    if (this.isExportAll) {
      const sortCriteriaList = new SortCriteriaList();
      const loggedEntity = McGod.getLoggedEntityIdFromToken();
      this.showExportMcb.setFalse();
      this.successExportMsg = `${McGod.t('cc.transactions.if-you-want-to-see-exported-file-click')} <a href="/#/entity/${loggedEntity}/out-factoring/sepa-out">${McGod.t('cc.payout.here')}.</a>`;
      this.toastr.success(`${McGod.t('cc.transactions.transactions-successfully-exported')}.`, '', { timeOut: 4000, extendedTimeOut: 4000 });
      setTimeout(() => this.actLoad(0, 100, this.pmTransactionFilter), 1000);
    }
  }

  actExportAllTransactions() {
    this.isExportAll = true;
    this.checkedTransactionList = this.pmTransactionList;
    this.transactionsExportedFlg = false;
    this.showExportMcb.setTrue();
  }

  actExportToCsv(){
    this.pmTransactionList.actExportToCsv(this.pmTransactionFilter, new WebFile());
  }

  onPauseTransaction() {
    const sortCriteriaList = new SortCriteriaList();
    this.showPauseTransactionMcb.setFalse();
    this.actLoad(0, 100, this.pmTransactionFilter);
    this.toastr.success(`${McGod.t('cc.transactions.transaction-s-was-paused-successfully')}.`, '', { timeOut: 4000, extendedTimeOut: 4000 });
  }

  onUnpauseTransaction() {
    const sortCriteriaList = new SortCriteriaList();
    this.showPauseTransactionMcb.setFalse();
    this.actLoad(0, 100, this.pmTransactionFilter);
    this.toastr.success(`${McGod.t('cc.transactions.transaction-s-was-unpaused-successfully')}.`, '', { timeOut: 4000, extendedTimeOut: 4000 });
  }

  onPauseTransactionCanceled() {
    this.showPauseTransactionMcb.setFalse();
    this.onItemsSelected(this.checkedTransactionList.items);
  }

  actShowExportMcb(transaction = null) {
    this.transactionsExportedFlg = false;
    this.showExportMcb.setTrue();
  }

  actShowRecordPaymentMcb() {
    if (this.isRecordPaymentPossible() && this.checkedTransactionList.items.every(item => item.amountDue > 0)) {
      this.showRecordPaymentMcb.setTrue();
    } else if(!this.isRecordPaymentPossible() && this.checkedTransactionList.items.every(item => item.amountDue > 0)) {
      this.onItemsSelected(this.checkedTransactionList.items);
      this.toastr.error(`${McGod.t('cc.transactions.record.payment.status-error')}.`, '', {timeOut: 4000, extendedTimeOut: 4000});
    } else if(this.isRecordPaymentPossible() && !this.checkedTransactionList.items.every(item => item.amountDue > 0)) {
      this.onItemsSelected(this.checkedTransactionList.items);
      this.toastr.error(`${McGod.t('cc.transactions.the-amount-due-for-one-or-more-transactions-is-0')}.`, '', {timeOut: 4000});
    } else {
      this.onItemsSelected(this.checkedTransactionList.items);
      this.toastr.error(`${McGod.t('cc.transactions.record.payment.status-error')}.`, '', {timeOut: 4000, extendedTimeOut: 4000});
      this.toastr.error(`${McGod.t('cc.transactions.the-amount-due-for-one-or-more-transactions-is-0')}.`, '', {timeOut: 4000});
    }
  }

  actCancelRecordPaymentMcb() {
    this.onItemsSelected(this.checkedTransactionList.items);
    this.showRecordPaymentMcb.setFalse();
  }

  actRecordPaymentSaved(){
    this.toastr.success(`The payment for ${this.checkedTransactionList.getCount()} transaction(s) was recorded successfully`, '', {timeOut: 4000, extendedTimeOut: 4000});
    this.showRecordPaymentMcb.setFalse();
    this.checkedTransactionList = new PmTransactionList();
    this.actLoad(0, 100, this.pmTransactionFilter);
  }

  isRecordPaymentPossible() {
    return this.checkedTransactionList.items.every(item => item.statusCd === PmTransaction.STATUS_CD_SENT_TO_INKASSO || item.statusCd === PmTransaction.STATUS_CD_RETURNED ||
      item.statusCd === PmTransaction.STATUS_CD_SENDING_TO_INKASSO || item.statusCd === PmTransaction.STATUS_CD_SHOULD_GO_TO_INKASSO ||
      item.statusCd === PmTransaction.STATUS_CD_ACCEPTED || item.statusCd === PmTransaction.STATUS_CD_RETURNED_FROM_INKASSO ||
      item.statusCd === PmTransaction.STATUS_CD_FOR_DUNNING ||
      item.statusCd === PmTransaction.STATUS_CD_REJECTED);
  }

  ngOnDestroy() {
    this.matBottomSheet.dismiss();
  }

  onColumnButtonAction(event: any) {
    if (event.actionCd === PmTransaction.ACTION_VIEW_CONSUMER_PROFILE) {
      this.actionPmTransaction = event.element;
      this.consumerId = event.element.csrConsumer.id;
      this.showConsumerProfileMcb.setTrue();
    }
  }

  onCancelStorno() {
    this.showStornoMcb.setFalse();
  }

  onSaveStorno() {
    this.showStornoMcb.setFalse();
    this.toastr.success(`${McGod.t('cc.common.the-transaction-was-successfully-canceled')}.`, '', {timeOut: 4000, extendedTimeOut: 4000});
    this.actLoad(0, 100, new PmTransactionFilter());
  }

  isStornoPossible(pmTransactionList: PmTransactionList) {
    const invalidStornoStatus = pmTransactionList.items.some(item => item.statusCd === PmTransaction.STATUS_CD_CANCELLED || item.statusCd === PmTransaction.STATUS_CD_EXPORTED
    || item.statusCd === PmTransaction.STATUS_CD_SETTLED);
    return invalidStornoStatus;
  }

  collectFilterValuesForExportAll(filters: MkFilterOutput) {
    this.pmTransactionFilter.outTransactionFlg = this.parentFlg;
    this.pmTransactionFilter.searchTerm = filters.search;
    this.pmTransactionFilter.paymentMethod = MkFilterOutput.convertFilterSelectionToArray(filters.selections[McGod.t(PmTransaction.FILTER_CREATE_TYPE)]);
    this.pmTransactionFilter.statusCds = MkFilterOutput.convertFilterSelectionToArray(filters.selections[McGod.t(PmTransaction.FILTER_STATUS)]);
    this.pmTransactionFilter.pausedFilterOptionCd = filters.selections[McGod.t(PmTransaction.FILTER_PAUSE)];
    this.pmTransactionFilter.childEntityIds = MkFilterOutput.convertFilterSelectionToArray(filters.selections[McGod.t(PmTransaction.FILTER_ENTITIES)]);
    this.pmTransactionFilter.dunningCompletedFilterOptionCd = filters.selections[McGod.t(PmTransaction.FILTER_DUNNING)];
    this.pmTransactionFilter.factoringTypeWithDelayFilterOptionCds = MkFilterOutput.convertFilterSelectionToArray(filters.selections[McGod.t(PmTransaction.FILTER_TYPE)]);
    this.pmTransactionFilter.transactionPaymentDueDateTimeFromDate = filters.selections[McGod.t(PmTransaction.FILTER_DATE_RANGE)]?.startDate;
    this.pmTransactionFilter.transactionPaymentDueDateTimeToDate = filters.selections[McGod.t(PmTransaction.FILTER_DATE_RANGE)]?.endDate;
  }
}
