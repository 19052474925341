<h3 class="page-title">{{'cc.common.export-for-inkasso-list' | translate}}</h3>

  <div class="col-md-12">
    <mcc-message [successMsg]="successMsg" [errorMsg]="errorMsg"></mcc-message>
  </div>

<mk-mat-table
  [isLoading]="isLoading"
  [items]="items"
  [config]="tableConfig"
  [itemsPerPage]="pageSize"
  [filterConfig]="filterConfig"
  [totalItemsCount]="totalItemsCount"
  (filterChanged)="onFilterChanged($event)"
  [mkMatMenuActionItems]="tableActionItems"
  (actionMkMatMenuItems)="onTableAction($event)"
>
</mk-mat-table>
<router-outlet></router-outlet>

