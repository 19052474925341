/* ------------------------------------------------------------------ */
/* -- DO NOT EDIT --------------------------------------------------- */
/* -- AUTO-GENERATED BY MTCN FRAMEWORK ------------------------------ */
/* ------------------------------------------------------------------ */
import {BaseObject} from '../_core/base-object';
import {CsrContract} from '../models/csr-contract.model';
import {CsrContractService} from '../services/csr-contract.service';
import {ICsrContract} from '../_generated/csr-contract.interface';
import {McGod} from '../extra/mc-god.service';
import {McValueLabelList} from '../_core/mc-value-label-list';
import {McDateUtils} from '../common/mc-date-utils';

import {CsrContractProductsList} from '../models/csr-contract-products-list.model';
import {CsrNotificationDetails} from '../models/csr-notification-details.model';
import {CsrRecurringDetails} from '../models/csr-recurring-details.model';
import {CsrPaymentDetails} from '../models/csr-payment-details.model';
import {CsrDiscount} from '../models/csr-discount.model';
import {McProductList} from '../models/mc-product-list.model';


export abstract class CsrContractGENERATED extends BaseObject {


    public static readonly STATUS_CD_NEW: string = 'NEW';
    public static readonly STATUS_CD_ACTIVE: string = 'ACTIVE';
    public static readonly STATUS_CD_PAUSED: string = 'PAUSED';
    public static readonly STATUS_CD_PENDING: string = 'PENDING';
    public static readonly STATUS_CD_CANCELED: string = 'CANCELED';
    public static readonly STATUS_CD_INACTIVE: string = 'INACTIVE';
    public static readonly STATUS_CD_WAITING_FOR_APPROVAL: string = 'WAITING FOR APPROVAL';
  public static readonly STATUS_CD_LIST__ALL = [
    CsrContractGENERATED.STATUS_CD_NEW,
    CsrContractGENERATED.STATUS_CD_ACTIVE,
    CsrContractGENERATED.STATUS_CD_PAUSED,
    CsrContractGENERATED.STATUS_CD_PENDING,
    CsrContractGENERATED.STATUS_CD_CANCELED,
    CsrContractGENERATED.STATUS_CD_INACTIVE,
    CsrContractGENERATED.STATUS_CD_WAITING_FOR_APPROVAL
];

  public apiService: CsrContractService;
  public _rawJson: ICsrContract;
    id: number = 0;
  approvalKey = '';
  autoRenewal: boolean;
  autoRenewalLength: number;
  cancellationDeadline: number;
  cancellationReason = '';
  consumer360Flg: boolean;
  consumerName = '';
  consumerStatusCd = '';
  contractCancellationActiveOnDate: number;
  contractCancellationDate: number;
  contractModel = '';
  contractNumber = '';
  contractSigningDate: number;
  contractType = '';
  description = '';
  durationInMonths: number;
  endDate: number;
  idBeneficiary: number;
  idCsrConsumer: number;
  idCsrContractPreDefined: number;
  idCsrDiscount: number;
  idExternal = '';
  idMcEntity: number;
  idWebFile: number;
  idWebfileDdApproval: number;
  idWebfileOther: number;
  idWebfileSignature: number;
  nextRecurring: number;
  notes = '';
  priceUnit: number;
  startDate: number;
  statusCd: string;
  sysCreatedDatetime: number;
  sysCreatedUserId: number;
  sysUpdatedDatetime: number;
  vatUnit: number;
  version: number;
  discount: CsrDiscount;  name: string;  notificationDetails: CsrNotificationDetails;  paymentDetails: CsrPaymentDetails;  products: McProductList;  productSnapshots: CsrContractProductsList;  recurringDetails: CsrRecurringDetails;

  public properties: string[] = ['id', 'approvalKey', 'autoRenewal', 'autoRenewalLength', 'cancellationDeadline', 'cancellationReason', 'consumer360Flg', 'consumerName', 'consumerStatusCd', 'contractCancellationActiveOnDate', 'contractCancellationDate', 'contractModel', 'contractNumber', 'contractSigningDate', 'contractType', 'description', 'durationInMonths', 'endDate', 'idBeneficiary', 'idCsrConsumer', 'idCsrContractPreDefined', 'idCsrDiscount', 'idExternal', 'idMcEntity', 'idWebFile', 'idWebfileDdApproval', 'idWebfileOther', 'idWebfileSignature', 'jsonbNotificationsDetails', 'jsonbPaymentDetails', 'jsonbRecurringDetails', 'nextRecurring', 'notes', 'priceUnit', 'startDate', 'statusCd', 'sysCreatedDatetime', 'sysCreatedUserId', 'sysUpdatedDatetime', 'vatUnit', 'version', 'discount', 'name', 'notificationDetails', 'paymentDetails', 'products', 'productSnapshots', 'recurringDetails'];
  public propertiesRegular: string[] = ['id', 'approvalKey', 'autoRenewal', 'autoRenewalLength', 'cancellationDeadline', 'cancellationReason', 'consumer360Flg', 'consumerName', 'consumerStatusCd', 'contractCancellationActiveOnDate', 'contractCancellationDate', 'contractModel', 'contractNumber', 'contractSigningDate', 'contractType', 'csrConsumer', 'csrContractPreDefined', 'description', 'durationInMonths', 'endDate', 'idBeneficiary', 'idCsrConsumer', 'idCsrContractPreDefined', 'idCsrDiscount', 'idExternal', 'idMcEntity', 'idWebFile', 'idWebfileDdApproval', 'idWebfileOther', 'idWebfileSignature', 'jsonbNotificationsDetails', 'jsonbPaymentDetails', 'jsonbRecurringDetails', 'nextRecurring', 'notes', 'priceUnit', 'startDate', 'statusCd', 'sysCreatedDatetime', 'sysCreatedUserId', 'sysUpdatedDatetime', 'vatUnit', 'version', 'name'];
  public propertiesSpecial: string[] = ['discount', 'notificationDetails', 'paymentDetails', 'products', 'productSnapshots', 'recurringDetails'];



  public static getStatusCdVll(): McValueLabelList {
    const list = new McValueLabelList();
        list.add(CsrContractGENERATED.STATUS_CD_NEW, McGod.t('New'));
    list.add(CsrContractGENERATED.STATUS_CD_ACTIVE, McGod.t('Active'));
    list.add(CsrContractGENERATED.STATUS_CD_PAUSED, McGod.t('Paused'));
    list.add(CsrContractGENERATED.STATUS_CD_PENDING, McGod.t('Pending'));
    list.add(CsrContractGENERATED.STATUS_CD_CANCELED, McGod.t('Canceled'));
    list.add(CsrContractGENERATED.STATUS_CD_INACTIVE, McGod.t('Inactive'));
    list.add(CsrContractGENERATED.STATUS_CD_WAITING_FOR_APPROVAL, McGod.t('WAITING FOR APPROVAL'));


    return list;
  }

  public static getStatusCdLabel(aCd: string): string {
    const list = CsrContractGENERATED.getStatusCdVll();
    return list.getLabel(aCd, '--BAD-CD--');
  }

  // ---------------------------------------------------------------------
public static createNewInstance(): CsrContract {
    console.error('MTCN-ERROR: Not Implemented: CsrContract::createNewInstance(). Add this method to final class and return new CsrContract();');
    throw new Error('Cannot CsrContract::createNewInstance(). Add this method to final class and return new CsrContract();');
}
  // ---------------------------------------------------------------------
  public static createFromJson(aItemJsonObj: ICsrContract): CsrContract {
    const item = this.createNewInstance(); //Object.create(this.prototype);
    item.populateFromJson(aItemJsonObj);
     //   item._rawJson = aItemJsonObj;
    // item =  Object.assign(item, aItemJsonObj, {
      // created: new Date(json.created)
      // libItemFiles: this.createLibItemFileObjectListFromJSON( aJsonObj.libItemFiles)
   // });
  //  item.prepare();
    return item;
  }

   // ---------------------------------------------------------------------
  constructor() {
    super(McGod.getService(CsrContractService));
  }

  // ---------------------------------------------------------------------
  protected _populateFromJson_specialProperties() {
    // console.log('RUNNING PREPARE CsrContract AABB()');
          if (this._rawJson['discount']) {
        this.discount = CsrDiscount.createFromJson(this._rawJson['discount']);
      }
      if (this._rawJson['notificationDetails']) {
        this.notificationDetails = CsrNotificationDetails.createFromJson(this._rawJson['notificationDetails']);
      }
      if (this._rawJson['paymentDetails']) {
        this.paymentDetails = CsrPaymentDetails.createFromJson(this._rawJson['paymentDetails']);
      }
      if (this._rawJson['products']) {
        this.products = McProductList.createFromJson(this._rawJson['products']);
      }
      if (this._rawJson['productSnapshots']) {
        this.productSnapshots = CsrContractProductsList.createFromJson(this._rawJson['productSnapshots']);
      }
      if (this._rawJson['recurringDetails']) {
        this.recurringDetails = CsrRecurringDetails.createFromJson(this._rawJson['recurringDetails']);
      }

  }

  // ---------------------------------------------------------------------

    public getPriceUnitStr(): string {
       return McGod.formatNumberToMoney(this.priceUnit);
    }

    public getSysCreatedDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.sysCreatedDatetime);
    }

    public getSysCreatedDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.sysCreatedDatetime);
    }

    public getSysUpdatedDatetimeStrD(): string {
       return McDateUtils.formatDateToString(this.sysUpdatedDatetime);
    }

    public getSysUpdatedDatetimeStr(): string {
       return McDateUtils.formatDateTimeToString(this.sysUpdatedDatetime);
    }

    public getVatUnitStr(): string {
       return McGod.formatNumberToMoney(this.vatUnit);
    }


  // ---------------------------------------------------------------------
  public toDto(): any {
    const dto = super.toDto();
    if (this.hasOwnProperty('discount')) {
        if (this.discount != null) {
            dto['discount'] = this.discount.toDto();
        } else {
            dto['discount'] = null;
        }
    }
    if (this.hasOwnProperty('notificationDetails')) {
        if (this.notificationDetails != null) {
            dto['notificationDetails'] = this.notificationDetails.toDto();
        } else {
            dto['notificationDetails'] = null;
        }
    }
    if (this.hasOwnProperty('paymentDetails')) {
        if (this.paymentDetails != null) {
            dto['paymentDetails'] = this.paymentDetails.toDto();
        } else {
            dto['paymentDetails'] = null;
        }
    }
    if (this.hasOwnProperty('products')) {
        if (this.products != null) {
            dto['products'] = this.products.toDto();
        } else {
            dto['products'] = [];
        }
    }
    if (this.hasOwnProperty('productSnapshots')) {
        if (this.productSnapshots != null) {
            dto['productSnapshots'] = this.productSnapshots.toDto();
        } else {
            dto['productSnapshots'] = [];
        }
    }
    if (this.hasOwnProperty('recurringDetails')) {
        if (this.recurringDetails != null) {
            dto['recurringDetails'] = this.recurringDetails.toDto();
        } else {
            dto['recurringDetails'] = null;
        }
    }

    return dto;
  }


  // -- CUSTOM METHODS --


  public getStatusCdLabel(): string {
    return CsrContractGENERATED.getStatusCdLabel(this.statusCd);
  }


 public  isStatusCdNEW(): boolean {
    const result = (this.statusCd === CsrContractGENERATED.STATUS_CD_NEW);

    return result;
  }

 public  isStatusCdACTIVE(): boolean {
    const result = (this.statusCd === CsrContractGENERATED.STATUS_CD_ACTIVE);

    return result;
  }

 public  isStatusCdPAUSED(): boolean {
    const result = (this.statusCd === CsrContractGENERATED.STATUS_CD_PAUSED);

    return result;
  }

 public  isStatusCdPENDING(): boolean {
    const result = (this.statusCd === CsrContractGENERATED.STATUS_CD_PENDING);

    return result;
  }

 public  isStatusCdCANCELED(): boolean {
    const result = (this.statusCd === CsrContractGENERATED.STATUS_CD_CANCELED);

    return result;
  }

 public  isStatusCdINACTIVE(): boolean {
    const result = (this.statusCd === CsrContractGENERATED.STATUS_CD_INACTIVE);

    return result;
  }

 public  isStatusCdWAITING_FOR_APPROVAL(): boolean {
    const result = (this.statusCd === CsrContractGENERATED.STATUS_CD_WAITING_FOR_APPROVAL);

    return result;
  }



}
