import { Component, OnInit } from '@angular/core';
import {
  CsrContractPreDefined,
  CsrContractPreDefinedService,
  CsrDiscount,
  CsrDiscountService,
  CsrReccuringOptionsService,
  CsrRecurringDetails,
  McGod,
  McProduct,
  McProductService,
  ServiceTypeCds,
  MembershipDurationsTypeCds,
  SortCriteriaItem,
  SortCriteriaList
} from "@miticon-ui/mc-core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute, Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {HttpParams} from "@angular/common/http";
import {MatRadioChange} from "@angular/material/radio";
import {ToastrService} from "ngx-toastr";
import {Location} from '@angular/common';
import {ICON_BACK} from "../../../../../assets/media/svg_icons/icon-back";
import {ICON_REMOVE} from "../../../../../assets/media/svg_icons/icon-remove";

@Component({
  selector: 'lib-contract-predefined-add-edit',
  templateUrl: './contract-predefined-add-edit.component.html',
  styleUrls: ['./contract-predefined-add-edit.component.scss']
})
export class ContractPredefinedAddEditComponent implements OnInit {
  products: McProduct[] = [];
  selectedProduct = new McProduct();
  selectedProducts: McProduct[] = [];
  form!: FormGroup;
  iconBack = ICON_BACK;
  iconRemove = ICON_REMOVE;
  contractId = 0;
  totalNetto: number = 0;
  totalBrutto: number = 0;
  totalAmount: number = 0;
  price: number = 0;
  priceOneTime: number = 0;
  isDiscountChecked: boolean = false;
  isLimitedDurationChecked: boolean = false;
  membershipDurations = MembershipDurationsTypeCds;
  todayDate: Date = new Date();
  chosenStartDate!: Date;
  discount: number = 0;
  totalVat: number = 0;
  discountList: CsrDiscount[] = [];
  isLandingDisabled: boolean = true;
  loadedMembership!: any;
  viewDiscount!: any;
  durationInMonths!: number;
  paymentOptions: any[] = [];
  selectedPaymentOptions: any[] = [];
  contractTypes = ServiceTypeCds;
  selectedType: string;


  constructor(private location: Location,
              private fb: FormBuilder,
              private ppProductService: McProductService,
              private dialog: MatDialog,
              private discountService: CsrDiscountService,
              private route: ActivatedRoute,
              private csrContractPreDefinedService: CsrContractPreDefinedService,
              private tS: TranslateService,
              private csrReccuringOptionsService: CsrReccuringOptionsService,
              private toastrService: ToastrService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.loadMembershipFromParam();
    this.createForm();
    this.checkForm();

    const sortCriteriaItem = new SortCriteriaItem('id', 'DESC');
    const sortCriteriaList = new SortCriteriaList();
    sortCriteriaList.addSort(sortCriteriaItem.propertyName, sortCriteriaItem.direction);
    const params = new HttpParams().set('page', 0).set('size',20);
    this.discountService.get(params,0,20, sortCriteriaList).subscribe(res => {
      this.discountList = res.content;
      this.discountList.sort((a,b) => {
        return a.value - b.value
      })
    })
  }

  createForm() {
    this.form = this.fb.group({
      statusCd: ['', Validators.required],
      name: ['', Validators.required],
      description: [''],
      service: ['', Validators.required],
      startDate: ['', Validators.required],
      endDate: ['',],
      visibilityCd: [CsrContractPreDefined.VISIBILITY_CD_PUBLIC],
      membershipDiscountBtn: ['', Validators.required],
      discount:[''],
      durationInMonths:['', Validators.required]
    });
  }

  checkForm() {
    this.form?.get('membershipDiscountBtn')?.valueChanges.subscribe((res: any) => {
      res ? this.form.get('discount')?.setValidators([ Validators.required]) : this.form.get('discount')?.clearValidators();
      this.form.get('discount')?.updateValueAndValidity({onlySelf : true});
    });
    this.form?.get('name')?.valueChanges.subscribe((res: any) => {
      if(!res.trim()) {
        this.form.get('name')?.setErrors({ incorrect: true });
      } else this.form.get('name')?.setErrors(null);
    });
  }

  onTypeChange(value: string) {
    this.selectedType = value;
    this.selectedProduct = new McProduct();
    this.form.controls['service'].reset();
    this.selectedProducts.forEach((product) => this.removeService(product));
    this.membershipDurations = MembershipDurationsTypeCds.filter((duration) => duration.types.includes(this.selectedType));

    this.ppProductService.getByType(this.selectedType).subscribe((res)=> {
      if (res) {
        this.products = res.map((object: any) => object = McProduct.createFromJson(object));
      }
    }, (error) => {
      alert(error)
    });
  }

  discountChange($event: MatRadioChange) {
    this.isDiscountChecked = $event.value === true;
    if ($event.value === false) {
      this.form.get('discount')?.reset()
    }
  }

  discountValueChange() {
    const discountControl = this.form.get('discount');
    discountControl?.value ? this.discount = discountControl?.value.value : this.discount = 0;

    this.calculatePrice();
  }

  durationInMonthsChange() {
    this.durationInMonths = this.form.controls['durationInMonths'].value;
    this.selectedPaymentOptions = [];
    this.paymentOptions = this.getPaymentOptions(this.durationInMonths);
    this.calculatePrice();
  }

  onServiceChange(productId: number) {
    this.selectedProduct = this.products.filter(product => product.id === productId)[0];
  }

  onStartChange($event: any) {
    this.chosenStartDate = $event.value;
  }

  addService(selectedProduct: McProduct) {
    if (this.selectedProducts.some(item => item.id === selectedProduct.id)) {
      return
    } else {
      selectedProduct.quantity = 1;
      this.selectedProducts.push((selectedProduct))
      this.calculateTotalMembershipPrice(selectedProduct, true)
    }
  }

  removeService(selectedProduct: McProduct) {
    this.calculateTotalMembershipPrice(selectedProduct, false, selectedProduct.noOfUnitsSelected)
    selectedProduct.noOfUnitsSelected = 1;
    this.selectedProducts = this.selectedProducts.filter(item => item !== selectedProduct)

    this.resetValuesIfNoSelectedServices();
  }

  calculateTotalMembershipPrice(product: McProduct, add: boolean, times?: number) {
    const unitPrice = times ? product.unitPrice! * times : product.unitPrice;

    const updatePrices = (price: number) => {
      if (add) {
        this.totalNetto += price;
        if (product.mcVat?.vatRate) {
          this.totalVat += (product.mcVat.vatRate / 100) * price;
        }
      } else {
        this.totalNetto -= price;
        if (product.mcVat?.vatRate) {
          this.totalVat -= (product.mcVat.vatRate / 100) * price;
        }
      }
      this.totalBrutto = Number((this.totalNetto + this.totalVat).toFixed(2));
    };

    const updateOneTimePrice = (price: number) => {
      const vatAmount = product.mcVat?.vatRate ? (product.mcVat.vatRate / 100) * price : 0;
      const totalPrice = price + vatAmount;
      if (add) {
        this.priceOneTime += totalPrice;
      } else {
        this.priceOneTime -= totalPrice;
      }
    };

    if (unitPrice) {
      if (product.chargingTypeCd === McProduct.CHARGING_TYPE_CD_CONTINUOUS) {
        updatePrices(unitPrice);
      } else {
        updateOneTimePrice(unitPrice);
      }
    }

    this.resetValuesIfNoSelectedServices();
    this.calculatePrice();
  }

  calculatePrice() {
    const total: number = this.totalBrutto - (this.discount / 100) * this.totalBrutto
    this.price = Math.round((total / 1) * 100) / 100;
    this.totalAmount = this.price;
  }

  onDateChange(event: any): any {
    const localDate = new Date(event.getTime() - (event.getTimezoneOffset() * 60000));
    return localDate.toISOString().split('T')[0];
  }

  openCreateDialog() {
    const createBody = (): any => ({
      typeCd: this.selectedType,
      description: this.form.value.description,
      durationInMonths: this.form.value.durationInMonths,
      endDate: this.form.value.endDate ? this.onDateChange(this.form.value.endDate) : null,
      name: this.form.value.name,
      startDate: this.onDateChange(this.form.value.startDate),
      statusCd: this.form.value.statusCd,
      visibilityCd: CsrContractPreDefined.VISIBILITY_CD_PUBLIC,
      products: this.selectedProducts.map((product: any) => {
        product._rawJson.quantity = 1;
        return product._rawJson;
      }),
      idCsrDiscount: this.form.get('discount')?.value?.id || undefined,
      priceUnit: 0,
      idMcEntity: Number(McGod.getLoggedEntityIdFromToken()),
      reccuringOptions: this.selectedPaymentOptions,
      totalNetto: this.getTotalNetto().toFixed(2),
      originalAmount: this.getTotalBrutto().toFixed(2),
      totalAmount: this.getTotalAmount().toFixed(2),
      price: this.price.toFixed(2),
      priceOneTime: this.priceOneTime.toFixed(2)
    });

    const updateBody = (): any => ({
      statusCd: this.form.get('statusCd')?.value,
      name: this.form.get('name')?.value,
      description: this.form.get('description')?.value,
      durationInMonths: this.loadedMembership.durationInMonths,
      startDate: this.loadedMembership.startDate,
      endDate: this.form.value.endDate ? this.onDateChange(this.form.value.endDate) : null,
      visibilityCd: this.loadedMembership.visibilityCd,
      products: this.loadedMembership.products,
      idCsrDiscount: this.loadedMembership.idCsrDiscount,
      priceUnit: this.loadedMembership.priceUnit,
      sysCreatedDatetime: this.loadedMembership.sysCreatedDatetime,
      sysCreatedUserId: this.loadedMembership.sysUpdatedUserId,
      sysUpdatedDatetime: this.loadedMembership.sysUpdatedDatetime,
      sysUpdatedUserId: this.loadedMembership.sysUpdatedUserId,
      id: this.loadedMembership.id,
      typeCd: this.loadedMembership.typeCd,
      discount: this.loadedMembership.discount,
      idMcEntity: Number(McGod.getLoggedEntityIdFromToken()),
      reccuringOptions: this.loadedMembership.reccuringOptions,
      totalNetto: this.getTotalNetto(),
      originalAmount: this.getTotalBrutto(),
      totalAmount: this.getTotalAmount(),
      price: this.price.toFixed(2),
      priceOneTime: this.priceOneTime.toFixed(2)
    });

    const handleSuccess = (message: string) => {
      this.location.back();
      this.toastrService.success(this.tS.instant(message));
    };

    const handleError = (error: any) => {
      this.toastrService.error(error);
    };

    if (!this.contractId) {
      this.csrContractPreDefinedService.insert(createBody()).subscribe(
        () => handleSuccess('cc.contract-360.created'),
        handleError
      );
    } else {
      this.csrContractPreDefinedService.update(updateBody()).subscribe(
        () => handleSuccess('cc.contract-360.updated'),
        handleError
      );
    }
  }


  resetValuesIfNoSelectedServices() {
    this.selectedProducts.length < 1 ? this.form.get('discount')?.reset() : '';
    this.selectedProducts.length < 1 ? this.form.get('durationInMonths')?.reset() : '';
    this.selectedProducts.length < 1 ? this.isLimitedDurationChecked = false : this.isLimitedDurationChecked;
    this.selectedProducts.length < 1 ? this.totalBrutto = 0 : this.totalBrutto;
    this.selectedProducts.length < 1 ? this.totalNetto = 0 : this.totalNetto;
    this.selectedProducts.length < 1 ? this.price = 0 : this.price;
  }

  goBack() {
    this.router.navigateByUrl(`entity/${McGod.getLoggedEntityIdFromToken()}/products/contracts-360`);
  }

  onStatusChange(status: MatRadioChange) {
    const visibilityControl = this.form.get('visibilityCd');
    this.isLandingDisabled = status.value === CsrContractPreDefined.STATUS_CD_ACTIVE;
    this.isLandingDisabled ? visibilityControl!.setValue('') : visibilityControl!.setValue(CsrContractPreDefined.VISIBILITY_CD_PRIVATE)
    this.contractId && this.form.get('visibilityCd')!.setValue(this.loadedMembership.visibilityCd);
  }

  loadMembershipFromParam() {
    this.route.params.subscribe((params) => {
      this.contractId = params['contractId'];
      if (this.contractId) return this.loadMembership();
    });
  }

  loadMembership() {
    this.csrContractPreDefinedService.getById(this.contractId).subscribe(res => {
      this.loadedMembership = res;
      this.todayDate = res.startDate ? new Date(res.startDate) : new Date();
      this.chosenStartDate = this.todayDate;
      if(res.typeCd) {
        this.selectedType = res.typeCd;
      }
      this.form.setValue({
        statusCd: res.statusCd,
        name: res.name,
        description: res.description,
        service: res.products[0].id,
        startDate: res.startDate,
        endDate: res.endDate ? res.endDate : null,
        visibilityCd: res.visibilityCd,
        membershipDiscountBtn: !!res.discount,
        discount: res.discount ? res.discount.value : null,
        durationInMonths: res.durationInMonths ? res.durationInMonths : null
      });
      this.selectedProducts = res.products;

      if(this.loadedMembership.idCsrDiscount) {
        this.discountService.getById(this.loadedMembership.idCsrDiscount).subscribe((discount) => {
          this.viewDiscount = discount;
        })
      }
      this.totalNetto = res.totalNetto ? res.totalNetto : 0;
      this.totalBrutto = res.originalAmount ? res.originalAmount : 0;
      this.totalAmount = res.totalAmount ? res.totalAmount : 0;
      this.price = res.price ? res.price : 0;
      this.priceOneTime = res.priceOneTime ? res.priceOneTime : 0;
    })
  }

  getPaymentOptions(durationInMonths: number): any[] {
    return CsrRecurringDetails.getReccuringTypes(Number(durationInMonths));
  }

  getTotalNetto() {
    return this.durationInMonths ? (this.totalNetto * this.durationInMonths) : this.totalNetto;
  }

  getTotalBrutto() {
    return this.durationInMonths ? (this.totalBrutto * this.durationInMonths) : this.totalBrutto;
  }

  getTotalAmount() {
    return this.durationInMonths ? (this.totalAmount * this.durationInMonths) : this.totalAmount;
  }

  getPriceLabel() {
    return CsrContractPreDefined.getLabel(this.selectedType).label;
  }

  getTypeCdLabel(typeCd: string) {
    return this.tS.instant(CsrContractPreDefined.getTypeCdLabel(typeCd));
  }
}
