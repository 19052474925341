import {McProductGENERATED} from "../_generated/mc-product-generated.model";

export const ChargingTypeCds = [
  { label: 'One time', value: McProductGENERATED.CHARGING_TYPE_CD_ONE_TIME },
  { label: 'Continuous', value: McProductGENERATED.CHARGING_TYPE_CD_CONTINUOUS }
]

export const ServiceTypeCds = [
  { label: 'cc.consumer.daily', value: McProductGENERATED.SERVICE_TYPE_CD_DAILY },
  { label: 'cc.consumer.weekly', value: McProductGENERATED.SERVICE_TYPE_CD_WEEKLY },
  { label: 'cc.consumer.monthly', value: McProductGENERATED.SERVICE_TYPE_CD_MONTHLY },
  { label: 'cc.consumer.quarterly', value: McProductGENERATED.SERVICE_TYPE_CD_QUARTERLY },
  { label: 'cc.consumer.half-yearly', value: McProductGENERATED.SERVICE_TYPE_CD_HALF_YEARLY },
  { label: 'cc.consumer.yearly', value: McProductGENERATED.SERVICE_TYPE_CD_YEARLY }
];

export const MembershipDurationsTypeCds = [
  {
    label: '1m',
    value: '1',
    types: [
      McProductGENERATED.SERVICE_TYPE_CD_DAILY,
      McProductGENERATED.SERVICE_TYPE_CD_WEEKLY,
      McProductGENERATED.SERVICE_TYPE_CD_MONTHLY
    ]
  },
  {
    label: '3m',
    value: '3',
    types: [
      McProductGENERATED.SERVICE_TYPE_CD_DAILY,
      McProductGENERATED.SERVICE_TYPE_CD_WEEKLY,
      McProductGENERATED.SERVICE_TYPE_CD_MONTHLY,
      McProductGENERATED.SERVICE_TYPE_CD_QUARTERLY
    ]
  },
  {
    label: '6m',
    value: '6',
    types: [
      McProductGENERATED.SERVICE_TYPE_CD_DAILY,
      McProductGENERATED.SERVICE_TYPE_CD_WEEKLY,
      McProductGENERATED.SERVICE_TYPE_CD_MONTHLY,
      McProductGENERATED.SERVICE_TYPE_CD_QUARTERLY,
      McProductGENERATED.SERVICE_TYPE_CD_HALF_YEARLY
    ]
  },
  {
    label: '12m',
    value: '12',
    types: [
      McProductGENERATED.SERVICE_TYPE_CD_DAILY,
      McProductGENERATED.SERVICE_TYPE_CD_WEEKLY,
      McProductGENERATED.SERVICE_TYPE_CD_MONTHLY,
      McProductGENERATED.SERVICE_TYPE_CD_QUARTERLY,
      McProductGENERATED.SERVICE_TYPE_CD_HALF_YEARLY,
      McProductGENERATED.SERVICE_TYPE_CD_YEARLY
    ]},
  {
    label: '24m',
    value: '24',
    types: [
      McProductGENERATED.SERVICE_TYPE_CD_DAILY,
      McProductGENERATED.SERVICE_TYPE_CD_WEEKLY,
      McProductGENERATED.SERVICE_TYPE_CD_MONTHLY,
      McProductGENERATED.SERVICE_TYPE_CD_QUARTERLY,
      McProductGENERATED.SERVICE_TYPE_CD_HALF_YEARLY,
      McProductGENERATED.SERVICE_TYPE_CD_YEARLY
    ]
  },
  {
    label: '36m',
    value: '36',
    types: [
      McProductGENERATED.SERVICE_TYPE_CD_DAILY,
      McProductGENERATED.SERVICE_TYPE_CD_WEEKLY,
      McProductGENERATED.SERVICE_TYPE_CD_MONTHLY,
      McProductGENERATED.SERVICE_TYPE_CD_QUARTERLY,
      McProductGENERATED.SERVICE_TYPE_CD_HALF_YEARLY,
      McProductGENERATED.SERVICE_TYPE_CD_YEARLY
    ]
  }
];
